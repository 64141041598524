<template>
  <b-card>
    <load-profile></load-profile>
    <div v-if="data.id">
      <b-row>
        <b-col>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("breadcrumb.applicantInformation") }}
          </b-card-title>
        </b-col>
        <b-col>
          <b-row align-h="end">
            <b-button
              v-if="data.additionalInfo"
              variant="success"
              :href="data.additionalInfo"
              class="mr-1"
              type="button"
              target="_blank"
            >
              {{ $t("button.downloadAdditionalInfo") }}
            </b-button>
            <b-button
              v-if="data.resume"
              variant="success"
              :href="data.resume"
              class="mr-1"
              type="button"
              target="_blank"
            >
              {{ $t("button.downloadResume") }}
            </b-button>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="auto" class="text-center">
          <h5 class="mt-1 mb-0 text-capitalize profile-name">
            {{ data.firstName }} {{ data.lastName }}
          </h5>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <p v-if="data.companyId">
            <feather-icon icon="LayersIcon" class="mr-1" />
            {{ data.companyNameEn }}
          </p>
          <p v-if="data.departmentId">
            <feather-icon icon="GitBranchIcon" class="mr-1" />
            {{ data.departmentNameEn }}
          </p>
        </b-col>
        <b-col>
          <p v-if="data.jobId">
            <feather-icon icon="BriefcaseIcon" class="mr-1" />
            {{ data.jobNameEn }}
          </p>
          <p v-if="data.sourceId">
            <feather-icon icon="LinkIcon" class="mr-1" />
            {{ data.sourceNameEn }}
          </p>
        </b-col>
        <b-col></b-col>
      </b-row>
      <hr />
      <b-row class="my-2">
        <b-col>
          <p v-if="data.email">
            <feather-icon icon="MailIcon" class="mr-1" /> {{ data.email }}
          </p>
          <p v-if="data.phoneNumber">
            <feather-icon icon="PhoneIcon" class="mr-1" />
            {{ data.phoneNumber }}
          </p>
        </b-col>
        <b-col>
          <p>
            <feather-icon icon="SendIcon" class="mr-1" />
            <b-badge pill variant="success" badge-glow v-if="data.telegram">
              {{ $t(`field.telegram`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.notTelegram`) }}
            </b-badge>
          </p>
          <p v-if="data.linkedinUrl">
            <feather-icon icon="LinkedinIcon" class="mr-1" />
            <a :href="data.linkedinUrl">{{ data.linkedinUrl }}</a>
          </p>
        </b-col>
        <b-col>
          <p>
            <feather-icon icon="CalendarIcon" class="mr-1" />
            {{ data.createdAt | formatDate("LL") }}
          </p>
        </b-col>
      </b-row>

      <div v-if="data.message">
        <h5 class="profile-name">Message</h5>
        <hr />
        <p>{{ data.message }}</p>
      </div>
    </div>
  </b-card>
</template>
    
<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";

const ApplicantRepository = Repository.get("applicant");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BAvatar,
    BImg,
    BBadge,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      data: {
        gender: "",
      },
      loading: false,
      avatarText,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      ApplicantRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };
        }
      });
    },
  },
  setup() {
    const resource = "applicant";
    const route = "applicant";

    return { resource, route };
  },
};
</script>
    